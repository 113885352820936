import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(ScrollTrigger, SplitText);

// When using Turbo, the turbo:load is triggered on the first page load as well. This is a workaround to prevent the first page load from triggering the init function twice.
let firstLoad = true;
document.addEventListener('DOMContentLoaded', function () {
  console.log('DOMContentLoaded');
  init();
});
document.addEventListener('turbo:render', function () {
  console.log('turbo:load');

  // Prevent the first page load from triggering the init function again (as it is already triggered by the DOMContentLoaded event)
  if (firstLoad) {
    firstLoad = false;
    return;
  }

  init();
});

function init() {
  console.log('init');
  let darkSections = gsap.utils.toArray('.dark-section');
  const mainMenuHeader = document.querySelector('#main-menu-frontpage');
  const textToSplit = document.querySelectorAll('.text-reveal-onScroll');
  const fadeInElements = document.querySelectorAll('.fadeIn');

  //Mask reveal animation on headings
  textToSplit.forEach((text, i) => {
    // Split the text into only lines and words
    const splitted = new SplitText(text, {
      wordsClass: 'word',
      type: 'lines,words',
    });

    // Prepare the text for animation
    gsap.set(splitted.lines, { textAlign: 'inherit', overflow: 'hidden' });
    gsap.set(splitted.words, { opacity: 0, y: 100 });
    gsap.set(text, { opacity: 1 });

    // Animate the words in each line
    let delay = 0;
    for (const line of splitted.lines) {
      gsap.to(line.querySelectorAll('.word'), {
        scrollTrigger: {
          trigger: line,
          start: 'top 85%',
          //markers:true
        },
        opacity: 1,
        y: 0,
        delay: delay,
      });
      delay += 0.1;
    }
  });

  //Fade in section elements
  fadeInElements.forEach((element, i) => {
    gsap.set(element, { opacity: 0 });
    gsap.to(element, {
      scrollTrigger: {
        trigger: element,
        start: 'top 85%',
        //markers:true
      },
      opacity: 1,
      duration: 1,
    });
  });

  // gsap.to(splitted.words, {
  //   scrollTrigger:{
  //     trigger: splitted.words,
  //     start: "top 85%",
  //     //markers:true
  //   },
  //   opacity:1,
  //   y:0,
  //   });
  // });

  // //Horizontal reveal for header horizontal list
  // let xValue = 0;
  // let staggerValue = 0.1;
  // if(windowWidth < 800) {
  //   xValue = -200;
  //   staggerValue = 0.25;
  // }
  // gsap.set(horizontalList, {opacity:0, x:xValue});
  //   ScrollTrigger.batch(horizontalList, {
  //     onEnter: batch => gsap.to(batch, {stagger:staggerValue, opacity: 1, x: 0}),
  //     start: "top 70%",
  // });

  // //Horizontal reveal for yellow blocks
  // gsap.set(horizontalYellowElements, {opacity:0, x:-200});
  //   ScrollTrigger.batch(horizontalYellowElements, {
  //     onEnter: batch => gsap.to(batch, {stagger:0.25, opacity: 1, x: 0}),
  //     start: "top 70%",
  // });

  darkSections.forEach((section, i) => {
    ScrollTrigger.create({
      trigger: section,
      start: 'top 80px',
      toggleClass: { targets: mainMenuHeader, className: 'over-dark' },
    });
  });
}
